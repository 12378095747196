<template lang="">
  <div>
    <template v-if="$i18n.locale === 'en'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">Career/</p></router-link>
                <h1>Java Architect</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>Job Description</h1>
                <p>MosApp is an instant messaging application that uses end-to-end encryption technology. It focuses on information security and protecting user privacy. The encrypted chat server does not save any information, and anyone outside the conversation cannot read or listen to it. You can safely chat and make audio and video calls with your friends and family. MosApp features include encrypted one-on-one and group chats, channel subscriptions, group chats with up to 10,000 people, burn after reading messages, two-way deletion, read receipts, and app lock screens.</p>
                <h1>Benefits</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>Qualification Requirement</h1>
                <ul class="list-style">
                  <li><p>Bachelor's degree or above, majoring in computer-related fields, obtained from a regular university.</p></li>
                  <li><p>More than 5 years of development experience, with at least 2 years of experience in technical architecture work, proficient in Java development, and rich experience in object-oriented development.</p></li>
                  <li><p>Experienced in large-scale product construction, familiar with key technologies in server and mobile front-end, familiar with product development and testing processes, as well as high concurrency and high availability production deployment.</p></li>
                  <li><p>Proficient in the relational database MySQL.</p></li>
                  <li><p>Familiarity with cross-platform product development, understanding of mobile front-end technologies, and preference given to those with knowledge of BPM, ESB, and WebSocket.</p></li>
                  <li><p>Strong sense of responsibility, ability to handle pressure, proactive and positive attitude, and a passion for learning</p></li>
                </ul>
                <h1>Responsibility</h1>
                <ul class="list-style">
                  <li><p>Responsible for the architecture design, detailed design, and implementation of software projects.</p></li>
                  <li><p>Plan and continuously optimize and upgrade the systems under responsibility to improve performance, stability, availability, and scalability of the projects.</p></li>
                  <li><p>Responsible for continuous optimization of high availability and high concurrency aspects of the architecture, with experience in designing highly available, highly concurrent, distributed, and microservices architectures.</p></li>
                  <li><p>Familiar with and proficient in middleware such as Redis, RocketMQ/Kafka, with a deep understanding of big data tools like HBase and Elasticsearch.</p></li>
                  <li><p>Involved in writing core code and guiding newcomers.</p></li>
                  <li><p>Conduct technical research and tackle technical challenges, overcoming difficult points in systems and projects.</p></li>
                  <li><p>Responsible for code reviews within the development team to ensure the quality of the team's output.</p></li>
                  <li><p>Responsible for the technical growth of the development team, ensuring continuous technical development through various methods.</p></li>
                </ul>
                <h1>Contact Info</h1>
                <p>CONTACT NAME : Ms.Camila</p>
                <p>PHONE NUMBER : <span style="color: #52C41A">(+855) 87699188</span></p>
                <!-- <p>ADDRESS : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>EMAIL : metathought.sg@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">Job Details</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Employment Type</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Vacancy</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">Deadline</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
    <template v-if="$i18n.locale === 'zh'">
      <div>
        <div class="banner">
          <div class="container">
            <el-row :gutter="20" class="content-head">
              <el-col :span="24">
                <router-link class="linkTo" to="/careers"><p style="margin-top: 100px; color: #3370FF">职业/</p></router-link>
                <h1>Java架构师</h1>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="container" style="margin-top: 30px;">
          <ApplyModal />
          <el-row :gutter="24" style="margin-top: 40px">
            <el-col :span="18">
              <div class="career-detail">
                <h1>岗位描述</h1>
                <p>MosApp 是一款使用端到端加密技术的即时通讯应用。它专注于信息安全和保护用户隐私。加密聊天服务器不保存任何信息，对话外的任何人都无法阅读或收听。您可以安全地与朋友和家人进行聊天，并进行音频和视频通话。MosApp 的功能包括加密的一对一和群组聊天，频道订阅，最多可容纳 10,000 人的群组聊天，阅后即焚消息，双向删除，已读回执以及应用程序锁定屏幕。</p>
                <h1>福利待遇</h1>
                <p>Good pay | Perfect attendance incentive | Employee referral incentive | NSSF | Opportunities for promotion | Large teamwork | Modern and comfortable office | Best office location | Public holidays follow labor law of the kingdom (100%) | Annual leave, maternity leave (3 months), paternity, special leave, sick leave, marriage leave, mourning leave...etc.</p>
                <h1>资格要求</h1>
                <ul class="list-style">
                  <li><p>第一学历统招一本及以上，计算机相关专业；</p></li>
                  <li><p>5年以上研发经验，2年以上技术架构工作经验，精通java开发，有丰富的面向对象开发经验。</p></li>
                  <li><p>具有大型产品建设的经验，了解服务端和移动前端的关键技术，熟悉产品开发. 测试流程；熟悉高并发. 高可用生产部署；</p></li>
                  <li><p>精通关系型数据库mysql；</p></li>
                  <li><p>对于跨平台产品开发有一定的了解，对移动端前端技术有一定的理解，对BPM，ESB，websokcet有一定了解优先考虑。</p></li>
                  <li><p>责任心强，有较强的抗压能力，积极向上，热爱学习。</p></li>
                </ul>
                <h1>职责</h1>
                <ul class="list-style">
                  <li><p>负责软件项目的架构设计. 详细设计. 实现;</p></li>
                  <li><p>对所负责系统进行规划，并结合项目不断优化和升级，提高性能. 稳定性. 可用性和扩展性;</p></li>
                  <li><p> 负责架构高可用高并发方面持续优化，需有高可用，高并发，分布式，微服务架构设计经验；</p></li>
                  <li><p>熟悉掌握对redis. rockemq/kafka等中间件，对hbase. es等大数据工具理解较深；</p></li>
                  <li><p> 核心代码编写以及新人指导;</p></li>
                  <li><p>进行技术预研和技术攻关，突破系统和项目中的技术难点;</p></li>
                  <li><p>负责研发团队代码评审，保证研发团队产出的质量； </p></li>
                  <li><p>负责研发团队的技术成长，通过各种方法保持研发团队的技术成长性；</p></li>
                </ul>
                <h1>联系信息</h1>
                <p>联系人姓名 : Ms.Camila</p>
                <p>电话号码 : <span style="color: #52C41A">(+855) 87699188</span></p>
                <!-- <p>地址 : 111 SOMERSET ROAD #08-05, 111 SOMERSET SINGAPORE (238164)</p> -->
                <p>电子邮件 : metathought.sg@gmail.com</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <h1 style="font-size:20px;">职位详情</h1>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">雇佣类型</p>
                  <p>Full-time</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">空缺职位</p>
                  <p>15 Positions</p>
                </div>
                <div style="font-size: 17px; margin-top: 15px;">
                  <p style="color:#8C8C8C">截止日期</p>
                  <p>31 Nov 2023</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ApplyModal from './ApplyModal.vue'

export default {
  components: {
    ApplyModal
  },
  data() {
    return { }
  }
}
</script>
<style lang="less" scoped>
.banner {
  position: relative;
  background-color: #E7EEFF99;
  background-position: center;
  height: auto;
  .container {
    color: #ffffff;
    position: relative;
    .content-head {
      padding-bottom: 40px;
      h1 {
        color: #1F1F1F;
        font-size: 50px;
        font-weight: 700;
      }
      p {
        font-size: 20px;
        font-weight: 400px;
      }
    }
  }
}
.career-detail {
  h1 {
    font-size: 26px;
    margin-bottom: 10px;
  }
  p {
    font-size: 17px;
    margin-bottom: 30px;
  }
}
ul.list-style {
  p {
    margin: 0;
  }
  margin-left: 35px;
  margin-bottom: 30px;
  li {
    list-style-type: inherit;
  }
}
</style>
